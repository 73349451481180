import User from "@/base/project/user.js";
import text from "@/base/text/index.js";


const saveUserIdAndReport = (services, params) => {
    const { storage, api } = services;

    return (dispatch, getState) => {
        const userIds = storage.local.loadUserIds();

        if (userIds.indexOf(params.userId) !== -1) {
            return;
        }

        userIds.push(params.userId);
        storage.local.saveUserIds(userIds);

        const state = getState();
        const session = state.user.session || "";

        api.user.newLocation({ session });
    };
};

const loadUserOptions = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        if (!state.user.session) {
            return;
        }

        const res = await services.api.user.getOptions({
            session: state.user.session,
        });

        const options = res.ok ? res.data || [] : [];

        dispatch(services.actions.user.setUserOptions({
            options: User.optionsArrToObj(options),
        }));
    };
};

const handleSignIn = (services, params) => {
    const { events, storage, actions } = services;
    const {
        sessionId,
        user,
        isRememberMe,
    } = params;

    return async (dispatch) => {
        events.user.signIn({
            session: sessionId,
        });

        if (isRememberMe) {
            storage.local.saveSession(sessionId);
            storage.session.saveSession("");
        } else {
            storage.local.saveSession("");
            storage.session.saveSession(sessionId);
        }

        dispatch(actions.user.setUserRememberMe(isRememberMe));
        dispatch(actions.user.setUserSession(sessionId));
        dispatch(actions.user.setUser(user));

        dispatch(loadUserOptions(services));

        const userId = user?.userId || "";

        if (userId) {
            dispatch(saveUserIdAndReport(services, { userId }));
        }
    };
};

const signIn = (services, params) => {
    const { api } = services;
    const { username, password, isRememberMe } = params;

    return async (dispatch) => {
        const res = await api.user.signIn({
            username,
            password,
        });

        let user = null;
        let secret = "";
        let error = "";
        let detectedLmsDomain = "";
        let isInactive = false;

        if (res.ok) {
            secret = res.secret;
            user = res.user;

            if (!secret) {
                dispatch(handleSignIn(services, {
                    sessionId: res.sessionId || "",
                    user,
                    isRememberMe,
                }));
            }
        } else {
            error = res.error || text.error;
            detectedLmsDomain = res.detectedLmsDomain || "";
            user = res.user || null;

            // TODO: replace later(with API fix)
            // isInactive = res.isInactive || false;
            isInactive = error.toLowerCase().indexOf("active") >= 0;
        }

        return {
            user,
            secret,
            error,
            detectedLmsDomain,
            isInactive,
        };
    };
};

const signInBySecret = (services, params) => {
    const { api } = services;
    const {
        username,
        secretCode,
        emailSecret,
        isRememberMe,
    } = params;

    return async (dispatch) => {
        const res = await api.user.signInBySecret({
            username,
            secretCode,
            emailCode: emailSecret,
        });

        let user = null;
        let error = "";

        if (res.ok) {
            user = res.user;

            dispatch(handleSignIn(services, {
                sessionId: res.sessionId || "",
                user,
                isRememberMe,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            user,
            error,
        };
    };
};

const saveUserId = (services, params) => {
    const { storage } = services;

    return () => {
        const userIds = storage.local.loadUserIds();

        if (userIds.indexOf(params.userId) !== -1) {
            return;
        }

        userIds.push(params.userId);
        storage.local.saveUserIds(userIds);
    };
};

const signUpByReferralCode = (services, params) => {
    const {
        actions,
        api,
        storage,
    } = services;

    return async (dispatch) => {
        const apiParams = {
            code: params.referralCode,
            email: params.email,
            password: params.password,
        };

        if (params.username) {
            apiParams.username = params.username;
        } else {
            apiParams.firstname = params.firstname;
            apiParams.lastname = params.lastname;
        }

        const res = await api.signup.signUpByReferralCode(apiParams);

        let sessionId = "";
        let error = "";
        let user = null;
        let detectedLmsDomain = "";

        if (res.ok) {
            sessionId = res.sessionId;
            user = res.user;

            if (params.isRememberMe) {
                storage.local.saveSession(sessionId);
                storage.session.saveSession("");
            } else {
                storage.local.saveSession("");
                storage.session.saveSession(sessionId);
            }

            dispatch(actions.user.setUserSession(sessionId));
            dispatch(actions.user.setUser(user));

            dispatch(saveUserId(services, {
                userId: user?.userId || "",
            }));
        } else {
            detectedLmsDomain = res.detectedLmsDomain || "";
            error = res.error || text.error;
        }

        return {
            error,
            detectedLmsDomain,
        };
    };
};

const loadSession = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.checkSession({
            session: state.user.session,
        });

        if (res.ok) {
            dispatch(actions.user.setUser(res.user));
        }
    };
};

const setUserLoading = (services) => {
    const { actions } = services;

    return async (dispatch) => {
        dispatch(actions.user.setUserLoading(true));
    };
};

const loadUser = (services) => {
    const { actions, storage, api } = services;

    return async (dispatch) => {
        let userSession = storage.session.loadSession();

        if (!userSession) {
            userSession = storage.local.loadSession();
        }

        if (!userSession) {
            dispatch(actions.user.setUserLoading(false));
            return {
                isError: true,
                isRedirect: false,
            };
        }

        dispatch(actions.user.setUserSession(userSession));

        const userRes = await api.user.checkSession({
            session: userSession,
        });

        if (!userRes.ok) {
            dispatch(actions.user.setUserLoading(false));

            storage.local.clearSession();

            return {
                isError: true,
                isRedirect: true,
            };
        }

        dispatch(actions.user.setUserRememberMe(true));
        dispatch(actions.user.setUser(userRes.user));

        dispatch(loadUserOptions({
            actions,
            api,
        }));

        return {};
    };
};

const signOut = (services, params) => {
    const {
        events,
        api,
        storage,
        actions,
    } = services;

    return (dispatch) => {
        console.log('here is location')
        console.log(params.location)
        events.user.signOut({
            session: params.session,
            location: params.location,
        });

        api.user.signOut({
            session: params.session,
        });

        storage.local.clearSession();
        storage.local.clearTeacherSelectedClass();

        dispatch(actions.clearAfterSignOut());

        // TODO: delete later
        if (services.goToSignIn) {
            services.goToSignIn();
        }
    };
};

const uploadAvatar = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.userSetAvatar({
            session: state.user.session,
            file: params.file,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setUserAvatar({
                avatarUrl: res.avatarUrl,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const deleteAvatar = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.userDeleteAvatar({
            session: state.user.session,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setUserAvatar({
                avatarUrl: "",
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const updateFullName = (services, params) => {
    const { api, actions } = services;
    const { fullName } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.updateFullName({
            session: state.user.session,
            fullName,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setUserFullName({
                fullName,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const updateEmailNotifications = (services, params) => {
    const { api, actions } = services;
    const { isEnabled } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.updateUserSettings({
            session: state.user.session,
            key: "isEmailNotificationEnabled",
            value: isEnabled,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setUserEmailNotification({
                isEnabled,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const updateAutoSubscription = (services, params) => {
    const { api, actions } = services;
    const { isEnabled } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.subscriptionChangeAutoRenew({
            session: state.user.session,
            subscriptionEncId: state.user?.user?.subscription?.subscriptionEncId,
            value: isEnabled,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setUserAutoRenewSubscription({
                isEnabled,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

/* --- */

const loadReadingMode = (services) => {
    const { actions, storage } = services;

    return (dispatch, getState) => {
        const state = getState();
        const { isRememberMe } = state.user;

        if (!isRememberMe) {
            return;
        }

        const isEnabledReadingMode = storage.local.loadReadingMode();

        dispatch(actions.user.setReadingMode({
            isEnabledReadingMode,
        }));
    };
};

const setReadingMode = (services, isEnable) => {
    const { actions, storage } = services;

    return (dispatch, getState) => {
        const state = getState();
        const { isRememberMe } = state.user;

        const isEnabledReadingMode = isEnable || false;

        if (isRememberMe) {
            storage.local.saveReadingMode(isEnabledReadingMode);
        }

        dispatch(actions.user.setReadingMode({
            isEnabledReadingMode,
        }));
    };
};

/* --- */

const openTeacherWelcomePopup = (services) => {
    const { actions } = services;

    return (dispatch) => {
        dispatch(actions.user.setTeacherWelcomePopupOpened());
    };
};

const closeTeacherWelcomePopup = (services) => {
    const { actions } = services;

    return (dispatch) => {
        dispatch(actions.user.setTeacherWelcomePopupClosed());
    };
};

/* --- */

const loadUserClassesLimitInfo = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.user.setClassesLimitInfoLoading());

        const res = await api.user.getClassesLimitInfo({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.user.setClassesLimitInfo({
            data,
            error,
        }));
    };
};

const reloadUserClassesLimitInfo = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.getClassesLimitInfo({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.user.setClassesLimitInfo({
            data,
            error,
        }));
    };
};


export default {
    saveUserIdAndReport,
    loadUserOptions,
    signIn,
    signInBySecret,
    signUpByReferralCode,

    saveUserId,
    loadSession,
    setUserLoading,
    loadUser,
    signOut,
    uploadAvatar,
    deleteAvatar,
    updateFullName,
    updateEmailNotifications,
    updateAutoSubscription,

    loadReadingMode,
    setReadingMode,

    openTeacherWelcomePopup,
    closeTeacherWelcomePopup,

    loadUserClassesLimitInfo,
    reloadUserClassesLimitInfo,
};
