import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        signIn(params) {
            console.log('here is location')
            console.log(ids.location)
            const event = {
                session: params.session,
                locationId: ids.location.login,
                eventId: ids.event.userLogin,
            };
            console.log('here is ids')
            console.log(ids)
            debugEvent("User sign inzzz", event);
            methods.sendEvent(event);
        },

        signOut(params) {
            console.log('here is pathname')
            console.log(params.location)
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session,
                locationId,
                eventId: ids.event.userLogout,
            };
            console.log('params')
            console.log(params)
            debugEvent("User sign out", event);
            methods.sendEvent(event);
        },

        changePassword(params) {
            const event = {
                session: params.session,
                locationId: ids.location.changePassword,
                eventId: ids.event.userChangePassword,
            };

            debugEvent("User change password", event);
            methods.sendEvent(event);
        },
        lmsLogInSuccessful(params) {

            const event = {
                session: "00000000000000000000000000000000",
                locationId: ids.event.userLogin,
                eventId: ids.event.lmsLoginSuccessful,
                objectId: params.code,
                subObjectId: params.user
            };

            debugEvent("LMS Login Successful", event);
            methods.sendEvent(event);
        },
        lmsLogInFailed(params) {
            console.log('here are paras')
            console.log(params)

            const event = {
                session: "00000000000000000000000000000000",
                locationId: ids.event.userLogin,
                eventId: ids.event.lmsLoginFailed,
                objectId: params.code,
                subObjectId: params.userId
            };

            debugEvent("LMS Login Failed", event);
            methods.sendEvent(event);
        }
    };
};
